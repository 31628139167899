












































// 1ra 65
// 2da 12
// 3ra 4
// Palé 1,200
// Tripleta 20,000
// Súper palé 3,000
import Vue from "vue";
export default Vue.extend({});
